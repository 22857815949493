import { ConfigElement, Provider } from '../../../types/local/tenant'

export const isAddConnectionFormValid = (
  connectionForm: Record<string, string | boolean>,
  shape: Array<ConfigElement>
): boolean => {
  const keys = shape
    .filter((key) => key.mode !== 'DISPLAY_MODE_HIDDEN')
    .filter((key) => !key.generated)
    .filter((key) => key.type !== 'CONFIG_ELEMENT_TYPE_BOOLEAN')
    .map((configElement) => String(configElement.name))
  return keys.every((key: string) => connectionForm[key])
}

export const shouldValidateConnectionFormBasedOnConfig = (provider: Provider) => {
  return (
    provider.connection_type === 'CONNECTION_TYPE_SIMPLE' ||
    provider.connection_type === 'CONNECTION_TYPE_UNKNOWN'
  )
}
