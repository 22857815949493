import coerce from 'semver/functions/coerce'
import semverCompareLoose from 'semver/functions/compare-loose'

const REPO_NAME_REGEXP = /^(?<baseName>.*[^\d])\d+$/

export const getDefaultRepoNameFromTemplate = (templateName: string, repos: string[]) => {
  const existingRepos = new Set(repos)

  const matchResult = REPO_NAME_REGEXP.exec(templateName)
  const baseName = matchResult?.groups?.baseName ?? templateName

  if (!existingRepos.has(baseName)) {
    return baseName
  }

  for (let suffix = 1; ; suffix++) {
    const candidateName = `${baseName}${suffix}`
    if (!existingRepos.has(candidateName)) {
      return candidateName
    }
  }
}

export const tagCompare = (a: string, b: string): -1 | 0 | 1 => {
  if (a.startsWith('latest') || b.startsWith('sha') || !coerce(b + '.0.0.0')) {
    return -1
  }
  if (b.startsWith('latest') || a.startsWith('sha') || !coerce(a + '.0.0.0')) {
    return 1
  }
  const semverComparison = semverCompareLoose(coerce(b + '.0.0.0')!, coerce(a + '.0.0.0')!)
  const lenComparison = semverComparison === 0 ? b.length - a.length : semverComparison

  if (lenComparison === 0) {
    return 0
  }
  return lenComparison < 0 ? -1 : 1
}
