import React, { Suspense } from 'react'
import { ReactQueryDevtools } from 'react-query/devtools'
import { Navigate, Route, Routes } from 'react-router-dom'
import { SuccessBannerProvider } from '@aserto/console-common'

import { NavBar } from './components/NavBar'
import CreateOrganizationModal from './components/tenants/CreateOrganizationModal'
import { useProfile } from './services/ProfileAndQueryClientProvider'

const AccountSettings = React.lazy(() => import('./views/AccountSettings'))
const AssociateRegistryImage = React.lazy(() => import('./views/AssociateRegistryImage'))
const CloneRegistryImage = React.lazy(() => import('./views/CloneRegistryImage'))
const Connections = React.lazy(() => import('./views/Connections'))
const CreatePolicy = React.lazy(() => import('./views/CreatePolicy'))
const CreatePolicyEmpty = React.lazy(() => import('./views/CreatePolicyEmpty'))
const CreatePolicyFromImage = React.lazy(() => import('./views/CreatePolicyFromImage'))
const CreatePolicyFromSample = React.lazy(() => import('./views/CreatePolicyFromSample'))
const CreatePolicyFromTemplate = React.lazy(() => import('./views/CreatePolicyFromTemplate'))
const CreatePolicyFromTemplateSchema = React.lazy(
  () => import('./views/CreatePolicyFromTemplateSchema')
)
const CreatePolicyInstance = React.lazy(() => import('./views/CreatePolicyInstance'))
const DirectoryBrowser = React.lazy(() => import('./views/DirectoryBrowser'))
const EditPolicyInstance = React.lazy(() => import('./views/EditPolicyInstance'))
const Error = React.lazy(() => import('./views/Error'))
const RegistryImageDetails = React.lazy(() => import('./views/RegistryImageDetails'))
const RegistryImages = React.lazy(() => import('./views/RegistryImages'))
const InvitationDetails = React.lazy(() => import('./views/InvitationDetails'))
const Logout = React.lazy(() => import('./views/Logout'))
const ManageTenant = React.lazy(() => import('./views/ManageTenant'))
const Policies = React.lazy(() => import('./views/Policies'))
const PolicyInstance = React.lazy(() => import('./views/PolicyInstance'))
const AuthorizersView = React.lazy(() => import('./views/Authorizers'))

function App() {
  const { tenant } = useProfile()

  return (
    <div id="app">
      <NavBar />
      <CreateOrganizationModal />
      <SuccessBannerProvider>
        <Suspense fallback={<></>}>
          <Routes>
            <Route element={<AuthorizersView />} path="/ui/authorizers" />
            <Route element={<Logout />} path="/logout" />
            <Route element={<Error />} path="/error" />
            <Route element={<Policies />} path="/" />
            <Route element={<Policies />} path="/ui/policies" />
            <Route element={<CreatePolicyInstance />} path="/ui/policies/createpolicyinstance" />
            <Route element={<PolicyInstance />} path="/ui/policies/:id/*" />
            <Route element={<EditPolicyInstance />} path="/ui/policies/:id/edit" />
            <Route element={<CreatePolicy />} path="/ui/images/createpolicy" />
            <Route element={<Connections />} path="/ui/connections" />
            <Route element={<CreatePolicyEmpty />} path="/ui/images/createpolicy/empty" />
            <Route element={<CreatePolicyFromImage />} path="/ui/policies/createpolicy/fromimage" />
            <Route
              element={<CreatePolicyFromSample />}
              path="/ui/policies/createpolicy/fromsample"
            />
            <Route
              element={<CreatePolicyFromTemplateSchema />}
              path="/ui/policies/createpolicy/fromtemplateschema"
            />
            <Route
              element={<CreatePolicyFromTemplate />}
              path="/ui/images/createpolicy/fromtemplate"
            />
            <Route element={<DirectoryBrowser />} path="/ui/directory/*" />
            <Route element={<RegistryImages />} path="/ui/images" />
            <Route element={<RegistryImages />} path="/ui/images/:connectionId" />
            <Route
              element={<RegistryImageDetails />}
              path="/ui/images/:connectionId/:organization/:name"
            />
            <Route element={<CloneRegistryImage />} path="/ui/images/clone" />
            <Route
              element={<AssociateRegistryImage />}
              path="/ui/images/:connectionId/:organization/:name/associate"
            />
            {tenant?.personal === false && [
              <Route key="/ui/manage-tenant" element={<ManageTenant />} path="/ui/manage-tenant" />,
            ]}
            <Route element={<AccountSettings />} path="/ui/account-settings" />
            <Route element={<InvitationDetails />} path="/ui/invitation-details/:id" />
            <Route element={<InvitationDetails />} path="/ui/invitation-details" />
            <Route element={<Navigate replace to="/" />} path="*" />
          </Routes>
        </Suspense>
      </SuccessBannerProvider>
      <ReactQueryDevtools initialIsOpen={false} />
    </div>
  )
}

export default App
