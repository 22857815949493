import styled from 'styled-components'
import { Row } from '@aserto/console-common'

export const Form = styled.form`
  max-width: 466px;
  padding: 20px;
  @media (max-width: 812px) {
    padding: 0;
  }
`

export const FormItem = styled.div`
  margin-bottom: 20px;
`

export const ButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 40px;
  button:first-of-type {
    margin-right: 10px;
  }
`

export const DeleteContainer = styled.div`
  flex-direction: column;
  font-family: Roboto;
  width: 80%;
  padding: 20px;
  @media (max-width: 812px) {
    display: block;
    width: 100%;
    padding: 0;
  }
`

export const LeftContainer = styled.div`
  min-width: 250px;
  margin-right: 30px;
  @media (max-width: 815px) {
    display: none;
  }
`

export const EmptyInvitesContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  img {
    margin-bottom: 10px;
  }
`

export const Text = styled.div<{ bold?: boolean; color?: string; size?: string }>`
  font-weight: ${({ bold }) => (bold ? 'bold' : 100)};
  font-size: ${({ size }) => size};
  color: ${({ color }) => color};
  padding-top: 8px;
`

export const SelectContainer = styled.div`
  width: 100%;
  display: none;
  margin-bottom: 20px;
  @media (max-width: 815px) {
    display: block;
    width: 100%;
  }
`

export const RightContainer = styled.div`
  padding-top: 20px;
  width: 98%;
  flex: 1;
`

export const TableContainer = styled.div`
  margin-top: -20px;
  width: 99%;
`

export const RowContainer = styled(Row)`
  flex-wrap: wrap;
  @media (max-width: 812px) {
    margin: 20px 20px;
  }
`
