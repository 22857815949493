import { useCallback } from 'react'

import { AddConnectionModalFormContent } from '../../components/connections/AddConnectionModal'
import { useOAuthConnector } from '../../components/OAuthConnectionProvider'
import { Provider } from '../../types/local/tenant'
import { useConfig } from '../ConfigProvider'

export const useOAuthConnectionMaker = () => {
  const { initiateOAuthFlow } = useOAuthConnector()
  const { oauthServiceUrl } = useConfig()

  return useCallback(
    async ({
      connectionInfo,
      provider,
      token,
      redirectUrlPath,
    }: {
      connectionInfo: AddConnectionModalFormContent
      provider: Provider
      token: string
      redirectUrlPath: string
    }) => {
      const providerName = provider.name!
      const providerId = provider.id!
      const providerKind = provider.kind!
      const redirectToUrl = `${location.origin}${redirectUrlPath}`
      initiateOAuthFlow({
        oauthServiceUrl,
        providerId,
        providerKind,
        providerName,
        redirectToUrl,
        token,
        connectionInfo,
      })
    },
    [initiateOAuthFlow, oauthServiceUrl]
  )
}
