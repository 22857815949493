import { useQuery } from 'react-query'

import { useAuthorizerInfoClient, useInfoClient } from '../clients/InfoClient'
import { v2InfoResponse } from '../types/local/authorizer'
import { QueryKeys, reactQueryDefaultOptions } from '../types/local/general'
import { InfoResponse } from '../types/local/tenant'

export const useInfo = () => {
  const { get } = useInfoClient()

  return useQuery([QueryKeys.Info], async () => await get<InfoResponse>({ path: 'info' }), {
    ...reactQueryDefaultOptions,
    retry: false,
    staleTime: Infinity,
  })
}

export const useAuthorizerInfo = () => {
  const { get } = useAuthorizerInfoClient()

  return useQuery(
    [QueryKeys.AuthorizerInfo],
    async () => await get<v2InfoResponse>({ path: 'info' }),
    {
      ...reactQueryDefaultOptions,
      retry: false,
      staleTime: Infinity,
    }
  )
}
