import { z } from 'zod'
import { components } from '@aserto/ts-console-backend/generated/console-backend'

type Schemas = components['schemas']
type ds0Tenant = Schemas['v1Tenant']

const Ds0TenantObjectPropertiesSchema = z
  .object({
    directory_v2: z.boolean().default(false),
    directory_v2_only: z.boolean().default(false),
    account: z
      .object({
        max_orgs: z.number().default(5),
        default_tenant_id: z.string().optional(),
        getting_started: z
          .object({
            show: z.boolean().default(true),
            steps: z
              .record(
                z.object({
                  completed: z.boolean().default(false),
                })
              )
              .default({}),
          })
          .default({ show: true, steps: {} }),
      })
      .optional(),
  })
  .passthrough()

type Ds0TenantObjectProperties = z.infer<typeof Ds0TenantObjectPropertiesSchema>

export type Ds0TenantObject = ds0Tenant & {
  properties: Ds0TenantObjectProperties
}

export const parseAsDs0TenantObjectProperties = (target: unknown = {}): Ds0TenantObjectProperties =>
  Ds0TenantObjectPropertiesSchema.parse(target)
