import 'bootstrap/dist/css/bootstrap.css'
import 'font-awesome/css/font-awesome.min.css'

import React from 'react'
import { createRoot } from 'react-dom/client'
import { QueryClient, QueryClientProvider } from 'react-query'
import { BrowserRouter } from 'react-router-dom'
import { ThemeProvider } from 'styled-components'
import { AsertoProvider } from '@aserto/aserto-react'
import {
  DirectoryContextProvider,
  ErrorModalProvider,
  FeatureFlagsProvider,
  GlobalStyle,
  PolicyEvaluatorContextProvider,
  SpinnerProvider,
  theme,
} from '@aserto/console-common'

import App from './App'
import OAuthConnectionProvider from './components/OAuthConnectionProvider'
import reportWebVitals from './reportWebVitals'
import Auth0IdentityProvider from './services/Auth0IdentityProvider'
import CachePreloader from './services/CachePreloader'
import ConfigProvider from './services/ConfigProvider'
import ConsoleCommonProvider from './services/ConsoleCommonProvider'
import FullStoryIdentify from './services/FullStory/FullStoryIdentify'
import FullStoryInit from './services/FullStory/FullStoryInit'
import OfflineBanner from './services/OfflineBanner'
import OnboardingProvider from './services/OnboardingProvider'
import PendoInit from './services/Pendo/PendoInit'
import ProfileAndQueryClientProvider from './services/ProfileAndQueryClientProvider'
import SentryEmbed from './services/SentryEmbed'
import ServiceInfoProvider from './services/ServiceInfoProvider'
import SmallScreenErrorModal from './services/SmallScreenErrorModal'
import VerifyEmailProvider from './services/VerifyEmailProvider'
import { AppStateContextProvider } from './ui/state/context'

const queryClient = new QueryClient()

const container = document.getElementById('root')
const root = createRoot(container!)
root.render(
  <>
    <SmallScreenErrorModal />
    <FeatureFlagsProvider featureFlags={{ isGraphQLEnabled: true, hasTemplates: true }}>
      <BrowserRouter>
        <QueryClientProvider client={queryClient}>
          <ThemeProvider theme={theme}>
            <GlobalStyle />
            <SpinnerProvider>
              <OfflineBanner />
              <ErrorModalProvider>
                <Auth0IdentityProvider>
                  <ConfigProvider>
                    <VerifyEmailProvider>
                      <FullStoryInit />
                      <SentryEmbed />
                      <ServiceInfoProvider>
                        <FullStoryIdentify />
                        <AsertoProvider>
                          <ProfileAndQueryClientProvider>
                            <OnboardingProvider>
                              <CachePreloader>
                                <PendoInit disabled />
                                <AppStateContextProvider>
                                  <OAuthConnectionProvider>
                                    <CachePreloader block>
                                      <ConsoleCommonProvider>
                                        <PolicyEvaluatorContextProvider>
                                          <DirectoryContextProvider>
                                            <App />
                                          </DirectoryContextProvider>
                                        </PolicyEvaluatorContextProvider>
                                      </ConsoleCommonProvider>
                                    </CachePreloader>
                                  </OAuthConnectionProvider>
                                </AppStateContextProvider>
                              </CachePreloader>
                            </OnboardingProvider>
                          </ProfileAndQueryClientProvider>
                        </AsertoProvider>
                      </ServiceInfoProvider>
                    </VerifyEmailProvider>
                  </ConfigProvider>
                </Auth0IdentityProvider>
              </ErrorModalProvider>
            </SpinnerProvider>
          </ThemeProvider>
        </QueryClientProvider>
      </BrowserRouter>
    </FeatureFlagsProvider>
  </>
)
reportWebVitals()
