import { useMutation, useQueries, useQuery, useQueryClient, UseQueryOptions } from 'react-query'

import { useTenantsClient } from '../../clients/TenantsClient'
import {
  MutationKeys,
  QueryKeys,
  ReactQueryMutationHooks,
  useMutationOptions,
} from '../../types/local/general'
import {
  GetPolicyStateResponse,
  RpcStatus,
  SetPolicyStateRequestBody,
  SetPolicyStateResponse,
} from '../../types/local/tenant'

export const useGetPolicyState = (
  policyId?: string,
  options?: Omit<
    UseQueryOptions<GetPolicyStateResponse, RpcStatus, GetPolicyStateResponse, QueryKeys[]>,
    'queryKey' | 'queryFn' | 'retry' | 'staleTime'
  >
) => {
  const { get } = useTenantsClient()

  return useQuery(
    [QueryKeys.GetPolicyState, policyId as QueryKeys],
    () => get<GetPolicyStateResponse>({ path: `policystate/${policyId}` }),
    {
      ...options,
      enabled: !!policyId && options?.enabled,
      retry: false,
      staleTime: Infinity,
    }
  )
}

export const useGetPolicyStates = (
  policyIds: string[],
  options?: Omit<
    UseQueryOptions<GetPolicyStateResponse, RpcStatus, GetPolicyStateResponse, QueryKeys[]>,
    'queryKey' | 'queryFn' | 'retry' | 'staleTime'
  >
) => {
  const { get } = useTenantsClient()

  return useQueries(
    policyIds.map((policyId) => {
      return {
        queryKey: [QueryKeys.GetPolicyState, policyId as QueryKeys],
        queryFn: async () => await get<GetPolicyStateResponse>({ path: `policystate/${policyId}` }),
        ...options,
        enabled: !!policyId && options?.enabled,
        retry: false,
        staleTime: Infinity,
      }
    })
  )
}

export const useSetPolicyState = (
  reactQueryHooks: ReactQueryMutationHooks<SetPolicyStateResponse, SetPolicyStateRequestBody> = {}
) => {
  const mutationOptions = useMutationOptions(reactQueryHooks)
  const { put } = useTenantsClient()
  const queryClient = useQueryClient()

  return useMutation(
    [MutationKeys.SetPolicyState],
    (body) =>
      put({
        body,
        path: `policystate`,
      }),
    {
      ...mutationOptions,
      onSuccess: (data, context) => {
        queryClient.invalidateQueries([QueryKeys.GetPolicyState, data.state?.policy?.id])
        queryClient.invalidateQueries([QueryKeys.PoliciesData])
        queryClient.invalidateQueries([QueryKeys.ListPolicy, data.state?.policy?.name])
        queryClient.invalidateQueries(QueryKeys.ListPolicy)
        queryClient.invalidateQueries([QueryKeys.IsPolicyNameAvailable, data.state?.policy?.name])
        queryClient.invalidateQueries([QueryKeys.PolicyData, data.state?.policy?.id])
        queryClient.invalidateQueries([QueryKeys.ListPolicyModules, data.state?.policy?.name])
        mutationOptions?.onSuccess?.(data, context)
      },
    }
  )
}
