import styled from 'styled-components'
import { theme } from '@aserto/console-common'

import { ButtonsContainer } from '../AccountSettings/styles'

export const SignupContainer = styled.div`
  width: 640px;
  margin: auto;
  min-height: 500px;
  border-radius: 8px;
  background-color: ${theme.primaryBlack};
`
export const PaddedContainer = styled.div`
  height: 100%;
  display: flex;
  background-color: black;
`
export const TextContainer = styled.div`
  padding: 30px;
`

export const TextArea = styled.div`
  font-size: 16px;
  margin-top: 16px;
  color: ${theme.grey100};
`
export const TextAreaBold = styled(TextArea)`
  font-weight: 600;
`

export const Image = styled.img`
  margin-bottom: 12px;
  margin-left: 20px;
`
export const Container = styled.div`
  display: flex;
  margin-bottom: 36px;
  gap: 10px;
`
export const Title = styled(TextAreaBold)`
  font-size: 20px;
  margin-left: 60px;
`
export const CelebrationImage = styled.img`
  margin-left: 68px;
  width: 24px;
  height: 24px;
`
export const RowPlacement = styled.div`
  display: flex;
  margin-top: 56px;
  align-items: center;
  flex-direction: column;
`

export const ButtonsContainerSignUp = styled(ButtonsContainer)`
  margin-top: 40px;
`
