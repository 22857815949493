import React, { Dispatch, useCallback, useContext, useMemo, useState } from 'react'
import { useStorage } from '@aserto/console-common'

import { OnboardingTemplate } from '../../types/local/consoleBackend'

export type OnboardingContextProps = {
  activeStep: number
  setActiveStep: Dispatch<React.SetStateAction<number>>
  setSelectedTemplate?: Dispatch<React.SetStateAction<OnboardingTemplate | undefined>>
  selectedTemplate?: OnboardingTemplate
  onboardingTenantId: string
  setOnboardingTenantId: Dispatch<React.SetStateAction<string>>
  fadeBackground: boolean
  setFadeBackground: Dispatch<React.SetStateAction<boolean>>
  clearSteps: () => void
}

const OnboardingContext = React.createContext<OnboardingContextProps>({
  activeStep: 1,
  fadeBackground: false,
  onboardingTenantId: '',
  selectedTemplate: undefined,
  setActiveStep: () => {},
  setFadeBackground: () => {},
  setOnboardingTenantId: () => {},
  setSelectedTemplate: () => {},
  clearSteps: () => {},
})

export const useOnboardingContext = () => {
  return useContext(OnboardingContext)
}

export const OnboardingContextProvider = ({ children }: { children: React.ReactNode }) => {
  const [activeStep, setActiveStep] = useStorage<number>('onboardingStep', 1)
  const [selectedTemplate, setSelectedTemplate] = useState<OnboardingTemplate | undefined>()
  const [onboardingTenantId, setOnboardingTenantId] = useState<string>('')
  const [fadeBackground, setFadeBackground] = useState<boolean>(false)
  const clearSteps = useCallback(() => {
    localStorage.removeItem('onboardingStep')
  }, [])

  const value = useMemo(
    () => ({
      activeStep,
      fadeBackground,
      onboardingTenantId,
      selectedTemplate,
      setActiveStep,
      setFadeBackground,
      setOnboardingTenantId,
      setSelectedTemplate,
      clearSteps,
    }),
    [
      activeStep,
      fadeBackground,
      onboardingTenantId,
      selectedTemplate,
      setActiveStep,
      setFadeBackground,
      setOnboardingTenantId,
      setSelectedTemplate,
      clearSteps,
    ]
  )

  return <OnboardingContext.Provider value={value}>{children}</OnboardingContext.Provider>
}
