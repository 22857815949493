import React, { PropsWithChildren, useCallback, useContext, useEffect, useState } from 'react'
import { useShowError } from '@aserto/console-common'

import { useSCCProviders } from '../api/connections'
import { AddConnectionModalFormContent } from '../components/connections/AddConnectionModal'
import { useHistory } from '../services/HistoryProvider'
import { useProfile } from '../services/ProfileAndQueryClientProvider'
import { redirectUsingWindow } from '../utils/general'

const OAuthConnectionProviderContext = React.createContext<OAuthConnectorContextProps>({
  popResponse: undefined,
  initiateOAuthFlow: () => {},
})

interface OAuthConnectorRequest {
  oauthServiceUrl: string
  providerId: string
  providerKind: string
  providerName: string
  redirectToUrl: string
  token: string
  connectionId?: string
  connectionInfo: AddConnectionModalFormContent
}

interface OAuthConnectorResponse {
  connectionName: string
  csrf: string
  message?: string
  providerName: string
}

export interface CreatedOAuthConnection {
  connectionName: string
  providerId: string
}

export interface OAuthConnectorContextProps {
  popResponse: (() => CreatedOAuthConnection) | undefined
  initiateOAuthFlow: (request: OAuthConnectorRequest) => void
}

export const useOAuthConnector = () => {
  return useContext(OAuthConnectionProviderContext)
}

const OAuthConnectionProvider: React.FC<PropsWithChildren<{}>> = ({ children }) => {
  const showError = useShowError()
  const history = useHistory()
  const { tenant } = useProfile()
  const { data: providersData } = useSCCProviders()
  const initiateOAuthFlow = useCallback(
    ({
      oauthServiceUrl,
      providerId,
      providerKind,
      providerName,
      redirectToUrl,
      token,
      connectionId,
      connectionInfo,
    }: OAuthConnectorRequest) => {
      const urlObject = new URL(oauthServiceUrl)
      // replace port for local development from 3000 to 8080
      if (urlObject.port && Number(urlObject.port) > 80) {
        urlObject.port = '8080'
      }
      const csrf = csrfToken()
      const cleanToken = token.replace('Bearer', '')
      localStorage.setItem(csrf, providerId)
      let url = `${urlObject}oauth/start/${providerName}/?url=${redirectToUrl}&csrf=${csrf}&kind=${providerKind}&tenant=${tenant?.id}&token=${cleanToken}&connectionId=${connectionId}`
      for (const [key, value] of Object.entries(connectionInfo)) {
        // prefix any provider-specific key with '_'
        const prefix = key === 'name' || key === 'description' || key === 'providerId' ? '' : '_'
        url = url.concat(`&${prefix}${key}=${value}`)
      }
      redirectUsingWindow(url)
    },
    [tenant?.id]
  )

  const [oauthResponse, setOauthResponse] = useState<CreatedOAuthConnection | null>(null)

  const hash = window.location.hash

  useEffect(() => {
    if (!hash) {
      return
    }

    const response = parseHash(hash)

    if (!response.csrf) {
      return
    }

    const providerIdForCsrf = localStorage.getItem(response.csrf)

    /* Protect against csrf (cross site request forgery https://bit.ly/1V1AvZD) */
    if (!providerIdForCsrf || !response?.providerName) {
      showError(new Error('Failed to establish OAuth connection. Please try again.'))
    } else {
      // clean up csrfToken
      setOauthResponse({
        connectionName: response.connectionName,
        providerId: providerIdForCsrf,
      })
      localStorage.removeItem(response.csrf)
      history.push(window.location.pathname + window.location.search)
    }
  }, [hash, history, providersData?.results, showError])

  return (
    <OAuthConnectionProviderContext.Provider
      value={{
        initiateOAuthFlow,
        popResponse: !oauthResponse
          ? undefined
          : () => {
              setOauthResponse(null)
              return oauthResponse
            },
      }}
    >
      {children}
    </OAuthConnectionProviderContext.Provider>
  )
}

const csrfToken = () => {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    const r = (Math.random() * 16) | 0,
      v = c == 'x' ? r : (r & 0x3) | 0x8 // eslint-disable-line
    return v.toString(16)
  })
}

const parseHash = (hash: string): OAuthConnectorResponse => {
  return Object.fromEntries(
    new URLSearchParams(hash.replace(/^#/, ''))
  ) as unknown as OAuthConnectorResponse
}

export default OAuthConnectionProvider
