import { useAsertoApiWithAuth, v1Api } from '@aserto/console-common'

import { useConfig } from '../services/ConfigProvider'

export function useRegistryClient() {
  const { tenantServiceUrl } = useConfig()
  const registryApi = `${v1Api(tenantServiceUrl)}/registry`

  return useAsertoApiWithAuth(registryApi)
}
