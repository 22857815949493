import { useEffect, useRef, useState } from 'react'

export const useQueryParam = (search: string) => {
  return new URLSearchParams(search)
}

export const useDeviceDetect = () => {
  const [isMobile, setMobile] = useState(false)

  useEffect(() => {
    if (window.innerWidth <= 920) {
      setMobile(true)
      return
    } else {
      setMobile(false)
      return
    }
  }, [])

  return { isMobile }
}

export const redirectUsingWindow = (url: string) => {
  window.location.assign(url)
}

export const useInputFocusRef = (dependencies: Array<unknown>) => {
  const inputRef = useRef<HTMLInputElement>(null)
  useEffect(() => {
    if (inputRef?.current) {
      inputRef?.current?.focus()
    }
  }, [
    // eslint-disable-next-line react-hooks/exhaustive-deps
    ...dependencies,
    inputRef,
  ])
  return inputRef
}
