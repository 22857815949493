import { useCallback } from 'react'
import { useInfiniteQuery, useMutation, useQuery, useQueryClient } from 'react-query'

import { useConsoleBackendClient } from '../clients/ConsoleBackendClient'
import { useHubSpotClient } from '../clients/HubSpotClient'
import { useRegistryClient } from '../clients/RegistryClient'
import { useProfile } from '../services/ProfileAndQueryClientProvider'
import {
  QueryKeys,
  reactQueryDefaultOptions,
  ReactQueryMutationHooks,
  useInfiniteQueryResultDecorations,
  useMutationOptions,
} from '../types/local/general'
import { AppiPublicOrg } from '../types/local/hardcodedBackendConstants'
import {
  ClonePolicyRepoRequestBody,
  ClonePolicyRepoResponse,
  CreatePolicyRepoRequestBody,
  CreatePolicyRepoResponse,
  DeletePolicyRepoResponse,
  GetTagResponse,
  ListPolicyReposResponse,
  ListPolicyRepoTagsResponse,
  ListRegistryOrgsResponse,
  ListRegistryRepoDigestsResponse,
  PolicyRepo,
  RegistryRepoAvailable,
  RegistryRepoDigest,
  RegistryRepoTag,
  RpcStatus,
  ValidPolicyRegistryRepoTagResponse,
} from '../types/local/tenant'
import { useApcrConnection, useAppiConnection } from './connections'

export const useApcrConnectionAndOrganization = () => {
  const { data: apcrConnection } = useApcrConnection()
  const { tenant } = useProfile()
  const connectionId = apcrConnection?.result?.id
  const organization = tenant?.name

  return {
    connectionId,
    organization,
  }
}

export const useApprConnectionAndOrganization = () => {
  const { data: opcrConnection } = useAppiConnection()

  return {
    connectionId: opcrConnection?.result?.id,
    organization: AppiPublicOrg,
  }
}

export const useRegistryOrganizations = ({ connectionId }: { connectionId: string | null }) => {
  const { get } = useRegistryClient()
  return useQuery(
    [QueryKeys.ListRegistryOrgs, connectionId],
    () =>
      get<ListRegistryOrgsResponse>({
        path: `${connectionId}`,
      }),
    {
      ...reactQueryDefaultOptions,
      enabled: !!connectionId,
    }
  )
}

export const usePolicyRepos = ({
  connectionId,
  organization,
  loadAllPages = false,
}: {
  connectionId: string | null
  organization: string | null
  loadAllPages?: boolean
}) => {
  const { get } = useRegistryClient()
  const result = useInfiniteQuery(
    [QueryKeys.ListPolicyRepos, connectionId, organization, loadAllPages],
    async ({ pageParam }) => {
      if (loadAllPages) {
        let pageParam = ''
        let data: PolicyRepo[] = []
        do {
          const response = await get<ListPolicyReposResponse>({
            path: `${connectionId}/${organization}`,
            queryParams: {
              'page.size': '10',
              ...(!!pageParam ? { 'page.token': pageParam } : {}),
            },
          })
          pageParam = response.page?.next_token || ''
          data = data.concat(response.registry_repos || [])
        } while (pageParam)

        return {
          page: {},
          registry_repos: data,
        }
      }
      return get<ListPolicyReposResponse>({
        path: `${connectionId}/${organization}`,
        queryParams: {
          'page.size': '10',
          ...(!!pageParam ? { 'page.token': pageParam } : {}),
        },
      })
    },
    {
      ...reactQueryDefaultOptions,
      enabled: !!connectionId && !!organization,
      getNextPageParam: (lastPage: ListPolicyReposResponse) => {
        const nextToken = lastPage?.page?.next_token
        const retVal = !!nextToken ? nextToken : undefined
        return retVal
      },
    }
  )

  const reducer = useCallback((accumulator: PolicyRepo[], current: ListPolicyReposResponse) => {
    return current.registry_repos ? accumulator.concat(current.registry_repos) : accumulator
  }, [])

  return useInfiniteQueryResultDecorations<ListPolicyReposResponse, RpcStatus, PolicyRepo>(
    result,
    reducer
  )
}

export const useApcrPolicyRepos = () => {
  const { connectionId, organization } = useApcrConnectionAndOrganization()

  return usePolicyRepos({
    connectionId: connectionId || null,
    organization: organization || null,
  })
}

export const usePolicyRepoTags = ({
  connectionId,
  organization,
  repositoryName,
  pollForTags = false,
  loadAllPages = false,
}: {
  connectionId: string | null
  organization: string | null
  repositoryName: string | null
  pollForTags?: boolean
  loadAllPages?: boolean
}) => {
  const { get } = useRegistryClient()
  const result = useInfiniteQuery(
    [QueryKeys.ListPolicyRepoTags, connectionId, organization, repositoryName, loadAllPages],
    async ({ pageParam }) => {
      if (loadAllPages) {
        let pageParam = ''
        let data: RegistryRepoTag[] = []
        do {
          const response = await get<ListPolicyRepoTagsResponse>({
            path: `${connectionId}/${organization}/${repositoryName}/tags`,
            queryParams: {
              'page.size': !!pageParam ? '10' : '30',
              ...(!!pageParam ? { 'page.token': pageParam } : {}),
            },
          })
          pageParam = response.page?.next_token || ''
          data = data.concat(response.tags || [])
        } while (pageParam)

        return {
          page: {},
          tags: data,
        }
      }
      return get<ListPolicyRepoTagsResponse>({
        path: `${connectionId}/${organization}/${repositoryName}/tags`,
        queryParams: {
          'page.size': !!pageParam ? '10' : '30',
          ...(!!pageParam ? { 'page.token': pageParam } : {}),
        },
      })
    },
    {
      ...reactQueryDefaultOptions,
      refetchInterval:
        pollForTags &&
        ((data) => {
          if (!data?.pages?.find((page) => page.tags?.find((tag) => tag.name === 'latest'))) {
            return 5000
          }
          return false
        }),
      enabled: !!connectionId && !!organization && !!repositoryName,
      getNextPageParam: (lastPage: ListPolicyRepoTagsResponse) => {
        const nextToken = lastPage?.page?.next_token
        const retVal = !!nextToken ? nextToken : undefined
        return retVal
      },
    }
  )

  const reducer = useCallback((accumulator: PolicyRepo[], current: ListPolicyRepoTagsResponse) => {
    return current.tags ? accumulator.concat(current.tags) : accumulator
  }, [])

  return useInfiniteQueryResultDecorations<ListPolicyRepoTagsResponse, RpcStatus, RegistryRepoTag>(
    result,
    reducer
  )
}

export const usePolicyRepoDigests = ({
  connectionId,
  organization,
  repositoryName,
  pollForDigests = false,
}: {
  connectionId: string | null
  organization: string | null
  repositoryName: string | null
  pollForDigests?: boolean
}) => {
  const { get } = useRegistryClient()
  const result = useInfiniteQuery(
    [QueryKeys.ListRegistryRepoDigests, connectionId, organization, repositoryName],
    ({ pageParam }) => {
      return get<ListRegistryRepoDigestsResponse>({
        path: `${connectionId}/${organization}/${repositoryName}/digests`,
        queryParams: {
          'page.size': !!pageParam ? '10' : '30',
          ...(!!pageParam ? { 'page.token': pageParam } : {}),
        },
      })
    },
    {
      ...reactQueryDefaultOptions,
      refetchInterval:
        pollForDigests &&
        ((data) => {
          if (
            !data?.pages?.find((page) =>
              page.digests?.some((digest) => digest.tags?.some((tag) => tag === 'latest'))
            )
          ) {
            return 5000
          }
          return false
        }),
      enabled: !!connectionId && !!organization && !!repositoryName,
      getNextPageParam: (lastPage: ListRegistryRepoDigestsResponse) => {
        const nextToken = lastPage?.page?.next_token
        const retVal = !!nextToken ? nextToken : undefined
        return retVal
      },
    }
  )

  const reducer = useCallback(
    (accumulator: RegistryRepoDigest[], current: ListRegistryRepoDigestsResponse) => {
      return current.digests ? accumulator.concat(current.digests) : accumulator
    },
    []
  )

  return useInfiniteQueryResultDecorations<
    ListRegistryRepoDigestsResponse,
    RpcStatus,
    RegistryRepoDigest
  >(result, reducer)
}

export const usePolicyRepoTag = ({
  connectionId,
  organization,
  repositoryName,
  tagName,
}: {
  connectionId: string | null
  organization: string | null
  repositoryName: string | null
  tagName: string | null
}) => {
  const { get } = useRegistryClient()
  return useQuery(
    [QueryKeys.ListPolicyRepoTags, connectionId, organization, repositoryName, tagName],
    () =>
      get<GetTagResponse>({
        path: `${connectionId}/${organization}/${repositoryName}/tags/${tagName}`,
      }),
    {
      ...reactQueryDefaultOptions,
      enabled: !!connectionId && !!organization && !!repositoryName && !!tagName,
    }
  )
}

export const useIsPolicyRepoTagValid = ({
  connectionId,
  organization,
  repositoryName,
  tagName,
}: {
  connectionId: string | null
  organization: string | null
  repositoryName: string | null
  tagName: string | null
}) => {
  const { get } = useRegistryClient()
  return useQuery(
    [QueryKeys.IsPolicyRepoTagValid, connectionId, organization, repositoryName, tagName],
    async () => {
      try {
        await get<ValidPolicyRegistryRepoTagResponse>({
          path: `${connectionId}/${organization}/${repositoryName}/tags/${tagName}/valid_policy`,
        })
        return true
      } catch (e) {
        return false
      }
    },
    {
      ...reactQueryDefaultOptions,
      enabled: !!connectionId && !!organization && !!repositoryName && !!tagName,
    }
  )
}

export const useCreatePolicyRepo = (
  mutationHooks: ReactQueryMutationHooks<CreatePolicyRepoResponse, { repositoryName: string }> = {}
) => {
  const { connectionId, organization } = useApcrConnectionAndOrganization()
  const { hubspotSetProperty } = useHubSpotClient()
  const queryClient = useQueryClient()
  const mutationOptions = useMutationOptions(
    {
      ...mutationHooks,
      onSuccess: async (...args) => {
        const name = 'created_policy_repository'
        try {
          await hubspotSetProperty({
            name,
            value: '1',
          })
        } catch (e) {}
        queryClient.removeQueries([QueryKeys.IsRegistryRepoAvailable])
        mutationHooks.onSuccess?.(...args)
      },
    },
    [QueryKeys.ListPolicyRepos, connectionId]
  )
  const { post } = useRegistryClient()

  return useMutation(({ repositoryName }: { repositoryName: string }) => {
    return post<CreatePolicyRepoResponse, CreatePolicyRepoRequestBody>({
      path: `${connectionId}`,
      body: {
        repo: {
          name: repositoryName,
          org: organization,
        },
      },
    })
  }, mutationOptions)
}

export const useClonePolicyRepo = (
  connectionId: string | undefined,
  mutationHooks: ReactQueryMutationHooks<ClonePolicyRepoResponse, ClonePolicyRepoRequestBody> = {}
) => {
  const { post } = useRegistryClient()
  const mutationOptions = useMutationOptions(mutationHooks, [
    QueryKeys.ListPolicyRepos,
    connectionId,
  ])
  const queryClient = useQueryClient()

  return useMutation(
    ({
      connectionId,
      source_org,
      source_repo,
      source_tag,
      destination_connection_id,
      destination_org,
      destination_repo,
      destination_tag,
    }: {
      connectionId: string
      source_org: string
      source_repo: string
      source_tag: string
      destination_connection_id: string
      destination_org: string
      destination_repo: string
      destination_tag: string
    }) => {
      return post<ClonePolicyRepoResponse, ClonePolicyRepoRequestBody>({
        path: `${connectionId}/clone`,
        body: {
          source_org: source_org,
          source_repo: source_repo,
          source_tag: source_tag,
          destination_connection_id: destination_connection_id,
          destination_org: destination_org,
          destination_repo: destination_repo,
          destination_tag: destination_tag,
        },
      })
    },
    {
      ...mutationOptions,
      onSuccess: (d, v, c) => {
        queryClient.removeQueries([QueryKeys.IsRegistryRepoAvailable, connectionId])
        mutationOptions?.onSuccess?.(d, v, c)
      },
    }
  )
}

export const useDeletePolicyRepo = (
  connectionId: string | undefined,
  mutationHooks: ReactQueryMutationHooks<
    DeletePolicyRepoResponse,
    { organization: string; repositoryName: string }
  > = {}
) => {
  const { del } = useConsoleBackendClient()
  const mutationOptions = useMutationOptions(
    mutationHooks,
    [QueryKeys.ListPolicyRepos, connectionId],
    [QueryKeys.ListPolicyRepoTags],
    [QueryKeys.PolicyBuilderData]
  )
  const queryClient = useQueryClient()

  return useMutation(
    ({ organization, repositoryName }: { organization: string; repositoryName: string }) => {
      return del<DeletePolicyRepoResponse>({
        path: `registry/${connectionId}/${organization}/${repositoryName}`,
      })
    },
    {
      ...mutationOptions,
      onSuccess: (d, v, c) => {
        queryClient.removeQueries([
          QueryKeys.IsRegistryRepoAvailable,
          connectionId,
          v.organization,
          v.repositoryName,
        ])
        queryClient.removeQueries([
          QueryKeys.ListRegistryRepoDigests,
          connectionId,
          v.organization,
          v.repositoryName,
        ])
        queryClient.removeQueries([QueryKeys.ListSccRepo])
        mutationOptions?.onSuccess?.(d, v, c)
      },
    }
  )
}

export const useIsRegistryRepoAvailable = ({
  connectionId,
  organization,
  repositoryName,
}: {
  connectionId?: string
  organization?: string
  repositoryName?: string
}) => {
  const { get } = useRegistryClient()
  return useQuery(
    [QueryKeys.IsRegistryRepoAvailable, connectionId, organization, repositoryName],
    () =>
      get<RegistryRepoAvailable>({
        path: `${connectionId}/available/${organization}/${repositoryName}`,
      }),
    {
      ...reactQueryDefaultOptions,
      enabled: !!connectionId && !!organization && !!repositoryName,
      staleTime: 0,
    }
  )
}
