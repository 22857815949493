import { useIdentity } from '@aserto/console-common'

import { useProfile } from '../../services/ProfileAndQueryClientProvider'
import { useConfig } from '../ConfigProvider'

const PendoInit = ({ disabled = false }: { disabled: boolean }) => {
  const { user } = useIdentity()
  const { account } = useProfile()
  const accountId = account?.id
  const { isProduction } = useConfig()

  if (!disabled && isProduction && !!user?.email && !!user?.name && !!user?.sub) {
    const id = accountId || ''
    pendo.initialize({
      visitor: {
        id: user.sub,
        email: user.email,
        full_name: user.name,
      },
      account: {
        id,
        // name:         // Optional
        // is_paying:    // Recommended if using Pendo Feedback
        // monthly_value:// Recommended if using Pendo Feedback
        // planLevel:    // Optional
        // planPrice:    // Optional
        // creationDate: // Optional
      },
    })
  }

  return null
}

export default PendoInit
