import { useAsertoApiWithAnonymousAccess, v1Api, v2Api } from '@aserto/console-common'

import { useConfig } from '../services/ConfigProvider'

export function useInfoClient() {
  const { tenantServiceUrl } = useConfig()

  return useAsertoApiWithAnonymousAccess(v1Api(tenantServiceUrl))
}

export function useAuthorizerInfoClient() {
  const { authorizerServiceUrl } = useConfig()

  return useAsertoApiWithAnonymousAccess(v2Api(authorizerServiceUrl))
}
