import React from 'react'

import OnboardingComponent from '../../components/Onboarding'
import { OnboardingContextProvider } from '../../services/OnboardingContextProvider'

const Onboarding = () => {
  return (
    <OnboardingContextProvider>
      <OnboardingComponent></OnboardingComponent>
    </OnboardingContextProvider>
  )
}
export default Onboarding
