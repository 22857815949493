import { useMemo } from 'react'
import {
  getAvailabilityReasonForConsole,
  useIsAvailableDebounced,
  UseIsNameAvailable,
} from '@aserto/console-common'

import { useIsConnectionNameAvailable } from '../../api/connections'

const useIsConnectionNameAvailableBase = (): UseIsNameAvailable =>
  useMemo(() => {
    const useIsConnectionNameAvailableInternal = (name: string, defaultName: string) => {
      const queryResult = useIsConnectionNameAvailable(name, { enabled: name !== defaultName })

      const response = useMemo(() => {
        const isAvailable =
          !queryResult?.data?.availability ||
          queryResult?.data?.availability === 'NAME_AVAILABILITY_AVAILABLE'

        return {
          isAvailable: isAvailable,
          reason: getAvailabilityReasonForConsole(queryResult?.data?.reason),
        }
      }, [queryResult?.data])

      return response
    }

    const useIsConnectionNameAvailableDebounced = (key: string, defaultkey: string) => {
      const useRunTarget = () => useIsAvailableDebounced(useIsConnectionNameAvailableInternal)
      const run = useMemo(useRunTarget, [])
      return run(key, defaultkey)
    }
    return useIsConnectionNameAvailableDebounced
  }, [])

export default useIsConnectionNameAvailableBase
