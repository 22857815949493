import { useIdentity } from '@aserto/console-common'
import * as FullStory from '@fullstory/browser'

import { useConfig } from '../ConfigProvider'

const FullStoryIdentify = () => {
  const { user } = useIdentity()
  const { isProduction } = useConfig()

  if (isProduction && !!user) {
    FullStory.identify(`${user.sub}`, {
      displayName: `${user.name}`,
      email: `${user.email}`,
      // TODO: Add your own custom user variables here, details at
      // https://help.fullstory.com/hc/en-us/articles/360020623294-FS-setUserVars-Recording-custom-user-data
      //reviewsWritten_int: 14
    })
  }

  return null
}

export default FullStoryIdentify
