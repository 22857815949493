import React, { useEffect, useMemo, useState } from 'react'
import { Button, Input, SpinnerToggle, useIdentity, useStorage } from '@aserto/console-common'

import { useAccountInvites } from '../../../api/members'
import { useClaimTenant, useValidateTenantName } from '../../../api/onboarding'
import AccountNameValidator from '../../../lib/validation/AccountNameValidator'
import { useOnboardingContext } from '../../../services/OnboardingContextProvider'
import { useProfile } from '../../../services/ProfileAndQueryClientProvider'
import { AccountTenantInvite } from '../../../types/local/tenant'
import { isInviteAcceptable } from '../../../utils/invites'
import { getVisualState } from '../../tenants/GetVisualState'
import { VisualStates } from '../../tenants/VisualStates'
import { ButtonContainer, InputContainer, ModalDescription } from '../styles'

const getInitialAccountName = (value: string) => {
  const name = value
    .toLowerCase()
    .replace(/[^a-zA-Z ]/g, '')
    .replace(/\s/g, '')

  return AccountNameValidator.isValid(name) ? name : ''
}

const CreateTenant = () => {
  const { setActiveStep, setOnboardingTenantId } = useOnboardingContext()
  const { user } = useIdentity()
  const [accountName, setAccount] = useState('')
  const [previousVisualState, setPreviousVisualState] = useState(VisualStates.VALID_FRAGMENT)
  const [_, setTenantId] = useStorage<string | undefined>('tenantId', undefined)
  const { account } = useProfile()

  useEffect(() => {
    if (!!user && user.nickname) {
      setAccount(getInitialAccountName(user.nickname))
    }
  }, [user])

  const { data: accountInvitesData, isLoading: isInvitesLoading } = useAccountInvites()
  const invites: Array<AccountTenantInvite> = useMemo(
    () => accountInvitesData?.results?.filter(isInviteAcceptable) || [],
    [accountInvitesData?.results]
  )

  useEffect(() => {
    if (!!account && account.personal_tenant && invites.length > 0) {
      setActiveStep(2)
    }
  }, [account, invites.length, setActiveStep])

  const claimTenant = useClaimTenant({
    onSuccess: ({ id }) => {
      setTenantId(String(id))
      setOnboardingTenantId(String(id))
      setActiveStep(2)
    },
  })

  const validateTenantName = useValidateTenantName(accountName)
  const visualState = getVisualState(
    previousVisualState,
    accountName,
    validateTenantName,
    claimTenant
  )

  useEffect(() => {
    if (visualState.kind === VisualStates.CLAIMING_FAILED.kind) {
      claimTenant.reset()
    }
  }, [claimTenant, visualState.kind])

  useEffect(() => setPreviousVisualState(visualState), [visualState])

  const storeAccount = async () => {
    claimTenant.mutate({ name: accountName, personal: true, default_artifacts: false })
  }

  const { errorMessage, isClaimable, isEditable, isLoading, isUnavailable, isValid } = visualState

  if (isInvitesLoading) {
    return null
  }

  return (
    <>
      <ModalDescription>
        Your account contains your authorization model and data. Your account name must be unique,
        and will be used as your tenant name.
      </ModalDescription>
      <InputContainer>
        <Input
          data-testid="tenant-name-input"
          disabled={!isEditable}
          error={errorMessage}
          info="Start with a letter. Must be 4 to 30 lowercase alphanumeric characters or dashes."
          isUnavailable={isUnavailable}
          isValid={isValid}
          label="Account name"
          value={accountName}
          onChange={(e) => setAccount(e.target.value)}
        />
        <ButtonContainer>
          <Button disabled={!isClaimable} variant="primary" onClick={storeAccount}>
            Create account
          </Button>
        </ButtonContainer>
      </InputContainer>
      <SpinnerToggle show={isLoading || isInvitesLoading} />
    </>
  )
}

export default CreateTenant
