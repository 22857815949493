import styled from 'styled-components'
import { ActionableInput } from '@aserto/console-common'

export const InfoContainer = styled.div`
  background-color: ${({ theme }) => theme.grey10};
  display: flex;
  @media (max-width: 812px) {
    flex-direction: column;
  }
  gap: 20px;
  justify-content: space-between;
  margin-top: -3px;
  min-height: 140px;
  padding: 1.875rem 20px;
  position: fixed;
  width: 100%;
  z-index: 1;
`

export const InputContainer = styled.div`
  input {
    max-width: 640px;
    min-width: 380px;
  }
`
export const StyledActionableInput = styled(ActionableInput)`
  width: 640px;
`

export const MultiElementRow = styled.div`
  display: flex;
  flex: row;
  gap: 10px;
`
export const LinkRegistry = styled.a`
  margin-bottom: 20px;
  background-size: cover;
  font-family: Ubuntu Mono;
  font-size: 16px;
  color: #67cbcf;
  line-height: 22px;
  text-decoration: none;
  max-width: 600px;
`
export const TitleSourceCode = styled.div`
  padding-bottom: 7px;
  font-family: Roboto;
  font-size: 16px;
  color: #e7e7e7;
  font-weight: 600;
`
export const RowPlacement = styled.div`
  display: flex;
  flex-direction: column;
`

export const NewTab = styled.img`
  padding-left: 10px;
  padding-bottom: 5px;
`

export const PullButtonContainer = styled.div`
  display: flex;
  align-items: flex;
  width: 100%;
  justify-content: flex;
  // overwrite ActionableInput margin from label
  margin-bottom: -8px;
  input {
    width: 420px;
  }
`

export const TagBadgeContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: flex-start;
  width: 100%;
`

export const TagBadgeItem = styled.div<{
  recommended: boolean
  selected: boolean
}>`
  background-color: ${({ theme }) => theme.grey20};
  background-size: cover;
  border-color: ${({ recommended, theme }) =>
    recommended ? theme.appleAccent1 : theme.indogoAccent3};
  border-radius: 36px;
  border: ${({ selected }) => (selected ? '2px solid' : 'none')};
  color: ${({ recommended, theme }) => (recommended ? theme.appleAccent1 : theme.indogoAccent3)};
  cursor: pointer;
  font-family: Roboto;
  font-size: 14px;
  height: 24px;
  line-height: 20px;
  min-width: 54px;
  padding-left: 6px;
  padding-right: 6px;
  padding-top: ${({ selected }) => (selected ? 'none' : '2px')};
  text-align: center;
  text-color: ${({ recommended, theme }) =>
    recommended ? theme.appleAccent1 : theme.indogoAccent3};
  text-decoration: none solid;
  white-space: nowrap;
`

export const NoTagsContainer = styled.div`
  padding: 60px;
`

export const NoTagsContent = styled.div`
  display: flex;
  flex-direction: column;
  margin: auto;
  width: 875px;
  span {
    height: 48px;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    background-color: #121212;
    background-size: cover;
    font-family: Roboto;
    font-size: 18px;
    color: #e7e7e7;
    text-decoration: none solid rgb(231, 231, 231);
    line-height: 24px;
  }
  p {
    height: 84px;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    background-color: #121212;
    background-size: cover;
    font-family: Roboto;
    font-size: 16px;
    color: #a0a0a0;
    text-decoration: none solid rgb(160, 160, 160);
    line-height: 24px;
  }
`
export const PaddedContentContainer = styled.div`
  padding-top: 180px;
`
export const PaddedContentBanner = styled.div`
  padding-top: 140px;
  padding-bottom: 180px;
  @media (max-width: 812px) {
    padding-bottom: 280px;
  }
`
export const OverflowCell = styled.span`
  display: block;
  word-break: break-word;
`
export const EmptyPolicyContainer = styled.div`
  background-color: ${({ theme }) => theme.grey10};
  justify-content: space-between;
  margin-top: 3px;
  padding: 20px;
  width: 100%;
`
export const CenteredContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`
