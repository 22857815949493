import React, { useMemo } from 'react'
import { QueryClientProvider, useQueryClient } from 'react-query'
import {
  BackendServicesAuthProvider,
  InternalConfigProvider,
  useIdentity,
} from '@aserto/console-common'

import { useDs0Account } from '../../api/ds0Account'
import Onboarding from '../../views/Onboarding'
import { useConfig } from '../ConfigProvider'

const OnboardingProvider: React.FC<React.PropsWithChildren<{}>> = ({ children }) => {
  const OnboardingContext = React.createContext({})
  const config = useConfig()
  const queryClient = useQueryClient()
  const activeStep = localStorage.getItem('onboardingStep')
  const { getAccessToken, logout } = useIdentity()
  if (window.location.pathname === '/logout') {
    logout()
  }

  const { data: accountData, error: accountError, isLoading: isAccountLoading } = useDs0Account()
  const account = useMemo(() => accountData?.result, [accountData?.result])
  const isLoading = !accountData || isAccountLoading
  const hasError = !!accountError
  if (hasError || isLoading) {
    return null
  }

  if ((!!account && !account.personal_tenant) || (!!activeStep && Number(activeStep) > 1)) {
    return (
      <OnboardingContext.Provider value={{}}>
        <QueryClientProvider client={queryClient}>
          <InternalConfigProvider
            config={{
              ...config,
              authorizerApiKey: '',
              directoryApiKey: '',
              directoryModelServiceUrl: '',
              directoryReaderServiceUrl: '',
              directoryWriterServiceUrl: '',
            }}
          >
            <BackendServicesAuthProvider
              backendServicesAuth={{
                getAuthorizerToken: getAccessToken,
                getDirectoryToken: getAccessToken,
              }}
            >
              <Onboarding />
            </BackendServicesAuthProvider>
          </InternalConfigProvider>
        </QueryClientProvider>
      </OnboardingContext.Provider>
    )
  }

  return <>{children}</>
}
export default OnboardingProvider
