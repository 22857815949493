import React, { PropsWithChildren, useContext, useEffect } from 'react'
import { InformationalError, useShowError } from '@aserto/console-common'

import { useConfig as useGetConfig } from '../../api/config'
import Config, { emptyConfig } from '../../types/local/config'

const configContext = React.createContext<Config>(emptyConfig)

export const useConfig = () => {
  const config = useContext(configContext)
  if (config === emptyConfig) {
    throw 'Config must be retrieved in the context of a <ConfigProvider>'
  }

  return config
}

const ConfigProvider: React.FC<PropsWithChildren<{}>> = ({ children }) => {
  const { data: config, error } = useGetConfig()
  const showError = useShowError()

  useEffect(() => {
    if (!!error) {
      showError(
        new InformationalError(
          'This may be a temporary error. Please try reloading this page. If the error persists, contact support@aserto.com.',
          'Looks like we are having issues with our services :('
        )
      )
    }
  }, [error, showError])

  return !config ? null : <configContext.Provider value={config}>{children}</configContext.Provider>
}

export default ConfigProvider
