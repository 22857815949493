import React, { useMemo } from 'react'
import { FullScreenModal } from '@aserto/console-common'

import { useAccountInvites } from '../../api/members'
import { useOnboardingContext } from '../../services/OnboardingContextProvider'
import { AccountTenantInvite } from '../../types/local/tenant'
import { isInviteAcceptable } from '../../utils/invites'
import ApplyTemplate from './ApplyTemplate'
import ConnectIdp from './ConnectIDP'
import CreateTenant from './CreateTenant'
import { Invitations } from './Invitations'

const OnboardingComponent = () => {
  const { activeStep, fadeBackground } = useOnboardingContext()
  const { data: accountInvitesData, isLoading } = useAccountInvites()

  const invites: Array<AccountTenantInvite> = useMemo(
    () => accountInvitesData?.results?.filter(isInviteAcceptable) || [],
    [accountInvitesData?.results]
  )

  const steps = [
    {
      title: `Onboarding in ${invites.length > 0 ? '4' : '3'} easy steps! First, name your account`,
      content: <CreateTenant></CreateTenant>,
    },
  ]

  if (invites.length > 0) {
    steps.push({
      title: `You've been invited to join an organization!`,
      content: <Invitations></Invitations>,
    })
  }

  steps.push(
    {
      title: 'Select an end-to-end sample, or an authorization template',
      content: <ApplyTemplate></ApplyTemplate>,
    },
    {
      title: 'Connect an identity provider',
      content: <ConnectIdp></ConnectIdp>,
    }
  )

  if (isLoading) {
    return null
  }

  return (
    <FullScreenModal
      fade={fadeBackground}
      show
      steps={{ current: activeStep, total: steps.length }}
      title={steps[activeStep - 1].title}
    >
      {steps[activeStep - 1].content}
    </FullScreenModal>
  )
}

export default OnboardingComponent
