import styled, { css } from 'styled-components'
import { theme } from '@aserto/console-common'

export const ButtonContainer = styled.div`
  margin-top: 24px;
  display: flex;
  flex-direction: row;
  gap: 8px;
`

export const ModalDescription = styled.p`
  margin-bottom: 0;
  font-size: 16px;
  line-height: 24px;
  width: 600px;
  margin-bottom: 24px;
`

export const InputContainer = styled.div`
  input {
    width: 560px;
  }
`

export const AllContent = styled.div`
  display: flex;
  gap: 24px;
`

export const CenteredContent = styled.div`
  flex: 2;
  display: flex;
  flex-direction: column;
  justify-content: center;
  @media (max-width: 1268px) {
    width: 300px;
  }
`

export const TemplatesDiv = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  overflow-y: auto;
  align-content: space-between;
  justify-content: space-between;
  gap: 24px;
  @media (max-width: 1268px) {
    align-content: flex-start;
    width: 312px;
    max-height: 456px;
  }
`

export const IconDiv = styled.div`
  display: flex;
  justify-content: center;
  gap: 4px;
  .icon {
    height: 32px;
  }
`

export const TemplateCard = styled.div<{ $active?: boolean }>`
  width: 288px;
  height: 136px;
  border-radius: 4px;
  padding: 20px 8px 8px 8px;
  background-color: ${theme.grey30};
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: 8px;
  p {
    margin-bottom: 0;
  }
  .template-title {
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
  }
  .template-desc {
    font-size: 12px;
    line-height: 16px;
  }
  &:hover {
    cursor: pointer;
  }
  ${({ $active }) =>
    !!$active &&
    css`
      border: 2px solid ${theme.primary};
      box-shadow: 0px 4px 4px 0px ${theme.grey10};
    `}}
`

export const InfoCard = styled.div`
  display: flex;
  flex-direction: column;
  padding: 8px;
  margin-top: 100px;
  margin-left: 12px;
  border-radius: 8px;
  width: 284px;
  box-shadow: 0px 0px 8px 0px ${theme.lochivarAccent2};
  font-size: 16px;
  .info-title {
    font-weight: 700;
  }
  .info-desc {
    font-weight: 400;
  }
  .info-link > a {
    text-decoration: none;
  }
  @media (max-width: 1268px) {
    width: 276px;
    margin-top: 165px;
  }
`

export const RightContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-between;
`

export const IdpsDiv = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  overflow-y: auto;
`

export const IdpCard = styled.div<{ $active?: boolean; $disabled?: boolean }>`
  display: flex;
  flex-direction: column;
  width: 600px;
  padding: 12px;
  border-radius: 4px;
  height: 120px;
  gap: 6px;
  background-color: ${theme.grey30};
  .idp-title {
    font-size: 16px;
    font-weight: 700;
    line-height: 20px;
  }
  .idp-desc {
    font-size: 12px;
    line-height: 16px;
  }
  .idp-icons {
    margin-top: 8px;
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
    &:hover {
      cursor: pointer;
    }
    img {
      width: 56px;
      height: 56px;
      border-radius: 50%;
      &:hover {
        box-shadow: 0px 0px 8px 4px ${theme.lochivarAccent2};
      }
    }
  }
  .default-idp-icons {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    img {
      width: 48px;
      height: 48px;
      border-radius: 50%;
    }
  }
  .connected {
    font-size: 12px;
    line-height: 16px;
    vertical-align: text-top;
    padding: 2px 4px;
    margin: 0 8px;
    border-radius: 4px;
    background-color: ${theme.indogo100};
  }
`

export const InvitationsContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: space-between;
  justify-content: center;
  gap: 24px;
`

export const InviteCard = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 600px;
  height: 92px;
  border-radius: 4px;
  padding: 0px 16px;
  background-color: ${theme.grey30};
  p {
    margin-bottom: 0;
  }
  .title {
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 8px;
  }
  .desc {
    font-size: 12px;
    line-height: 16px;
  }
`
