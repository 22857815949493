import { useCallback } from 'react'

import { useConnections, useProviders } from '../api/connections'
import { getProviderById } from '../lib/connection'
import { useBasicAuthConnectionMaker } from '../services/connectionMakers/basicAuthConnectionMaker'
import { useEnvConfig } from '../services/EnvConfigProvider'
import { ProviderId } from '../types/local/hardcodedBackendConstants'
import { Connection, Provider, ProviderKind } from '../types/local/tenant'

export enum LogoType {
  Aserto,
  Custom,
  Placeholder,
}
interface RegistryURL {
  providerId: string
  registryUrl: string
  registryName: string
}

const REGISTRY_URLS: RegistryURL[] = [
  {
    providerId: ProviderId.OPCR,
    registryUrl: 'opcr.io/',
    registryName: 'opcr.io',
  },
  {
    providerId: ProviderId.AsertoPublicPolicyImages,
    registryUrl: 'ghcr.io/',
    registryName: 'ghcr.io',
  },
  {
    providerId: ProviderId.GHCR,
    registryUrl: 'ghcr.io/',
    registryName: 'ghcr.io',
  },
]

const ASERTO_PROVIDER_KINDS = new Set<ProviderKind>([
  'PROVIDER_KIND_AUTHORIZER',
  'PROVIDER_KIND_DECISION_LOGS',
  'PROVIDER_KIND_DIRECTORY',
  'PROVIDER_KIND_DISCOVERY',
  'PROVIDER_KIND_POLICY_REGISTRY',
])

export function getLogoTypeForConnection(connection: Connection): LogoType {
  switch (connection.kind!) {
    case 'PROVIDER_KIND_AUTHORIZER':
    case 'PROVIDER_KIND_DECISION_LOGS':
    case 'PROVIDER_KIND_DIRECTORY':
    case 'PROVIDER_KIND_DISCOVERY':
      return LogoType.Aserto
    case 'PROVIDER_KIND_POLICY_REGISTRY':
      return isAsertoConnection(connection) ? LogoType.Aserto : LogoType.Custom
    case 'PROVIDER_KIND_UNKNOWN':
    case 'PROVIDER_KIND_IDP':
    case 'PROVIDER_KIND_SCC':
    case 'PROVIDER_KIND_EDGE_AUTHORIZER':
      return LogoType.Custom
    default:
      return LogoType.Placeholder
  }
}

const REGISTRY_CONNECTIONS = [ProviderId.OPCR, ProviderId.GHCR]

export const isAsertoProvider = (provider: Provider) => {
  return (
    ASERTO_PROVIDER_KINDS.has(provider.kind!) &&
    REGISTRY_CONNECTIONS.every((id) => provider.id !== id)
  )
}

export const isAsertoConnection = (connection: Connection): boolean =>
  ASERTO_PROVIDER_KINDS.has(connection.kind!) &&
  REGISTRY_CONNECTIONS.every((id) => connection.provider_id !== id)

export const isReadOnlyConnection = (connection: Connection): boolean => {
  return connection.provider_id === ProviderId.AsertoPublicPolicyImages
}

export const useEnsureSimpleConnection = (setConnectionId?: (connectionId: string) => void) => {
  const { data: providersData } = useProviders()
  const { data: connectionsData } = useConnections()

  const hasExistingConnection = useCallback(
    (providerId: string) =>
      connectionsData?.results?.find((connection) => connection.provider_id === providerId),
    [connectionsData?.results]
  )

  const makeBasicAuthConnection = useBasicAuthConnectionMaker({
    setIsLoading: () => {},
    setCreatedConnectionId: setConnectionId,
  })

  return useCallback(
    (providerId: string) => {
      if (!!hasExistingConnection(providerId)) {
        return
      }

      const providers = providersData?.results || []
      const selectedProvider = getProviderById(providers, providerId)
      const connectionInfo = {
        name: selectedProvider!.name!,
        providerId,
        description: '',
      }
      makeBasicAuthConnection({ content: connectionInfo, selectedProvider })
    },
    [hasExistingConnection, makeBasicAuthConnection, providersData?.results]
  )
}

export const useHasExistingConnection = (providerId: string) => {
  const { data: connectionsData } = useConnections()
  return connectionsData?.results?.find((connection) => connection.provider_id === providerId)
}

export const useRegistryName = (connection: Connection): string => {
  if (!connection || !connection.provider_id) {
    return ''
  }
  if (connection.provider_id === ProviderId.AsertoRegistry) {
    return 'Aserto Policy Registry'
  }
  return REGISTRY_URLS.find((reg) => reg.providerId === connection.provider_id)?.registryName || ''
}

export const useRegistryURL = (connection: Connection): string => {
  const { environment } = useEnvConfig()
  if (!connection || !connection.provider_id) {
    return ''
  }
  if (connection.provider_id === ProviderId.AsertoRegistry) {
    return environment === 'PRODUCTION'
      ? 'registry.prod.aserto.com/'
      : environment === 'INT'
      ? 'registry.int.aserto.com/'
      : 'registry.eng.aserto.com/'
  }
  return REGISTRY_URLS.find((reg) => reg.providerId === connection.provider_id)?.registryUrl || ''
}
