import React from 'react'
import styled from 'styled-components'
import { Button, CardModal } from '@aserto/console-common'

type DeleteManifestModalProps = {
  show: boolean
  onHide: () => void
  onClickRemove: () => void
}

const ContentContainer = styled.div`
  padding: 20px;
  width: 100%;
  height: fit-content;
`
const TextArea = styled.div`
  font-weight: 600;
  font-size: 16px;
`

const ButtonsContainer = styled.div`
  width: 100%;
  flex: 1 1 0%;
  justify-content: flex-end;
  align-items: flex-end;
  float: right;
  display: flex;
  margin-top: 20px;
  button:first-of-type {
    margin-right: 10px;
  }
`

const DeleteManifestModal: React.FC<DeleteManifestModalProps> = ({
  onHide,
  show,
  onClickRemove,
}) => {
  return (
    <>
      <CardModal
        backgroundColor="black"
        cardHeight="100%"
        show={show}
        title="Remove directory data?"
        variant="delete_danger"
        onHide={onHide}
      >
        <ContentContainer>
          <TextArea>
            Changing to a different template will delete all your directory data, and replace it
            with the sample data that comes with this template.
          </TextArea>
          <br />
          <TextArea>
            If you connected your own identity providers, we will reconnect them and re-import the
            data. However, any data that you added to the directory will be lost.
          </TextArea>
          <br />
          <TextArea>Are you sure you want to delete your data?</TextArea>
          <ButtonsContainer>
            <Button variant="secondary" onClick={onHide}>
              Cancel
            </Button>
            <Button data-testid="delete-subject" variant="danger" onClick={onClickRemove}>
              Delete data
            </Button>
          </ButtonsContainer>
        </ContentContainer>
      </CardModal>
    </>
  )
}

export default DeleteManifestModal
