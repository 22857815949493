import { useMutation, useQuery, useQueryClient, UseQueryOptions } from 'react-query'

import { useTenantsClient } from '../../clients/TenantsClient'
import { useProfile } from '../../services/ProfileAndQueryClientProvider'
import { QueryKeys, ReactQueryMutationHooks, useMutationOptions } from '../../types/local/general'
import {
  DeletePolicyResponse,
  ListPolicyFilter,
  ListPolicyResponse,
  PolicyNameAvailableResponse,
  RpcStatus,
} from '../../types/local/tenant'

export const useListPolicy = (
  policyFilter?: ListPolicyFilter,
  options?: Omit<
    UseQueryOptions<ListPolicyResponse, RpcStatus, ListPolicyResponse, QueryKeys[]>,
    'queryKey' | 'queryFn' | 'retry' | 'staleTime'
  >
) => {
  const { get } = useTenantsClient()
  const queryKeys = [QueryKeys.ListPolicy, JSON.stringify(policyFilter) as QueryKeys]

  return useQuery(
    queryKeys,
    () =>
      get<ListPolicyResponse>({
        path: `policies`,
        queryParams: { filter: { ...policyFilter } },
      }),
    {
      ...options,
      enabled: options?.enabled,
      retry: false,
      staleTime: Infinity,
    }
  )
}

export const useIsPolicyNameAvailable = (
  policyName: string,
  options?: Omit<
    UseQueryOptions<
      PolicyNameAvailableResponse,
      RpcStatus,
      PolicyNameAvailableResponse,
      QueryKeys[]
    >,
    'queryKey' | 'queryFn' | 'retry' | 'staleTime'
  >
) => {
  const { get } = useTenantsClient()
  return useQuery(
    [QueryKeys.IsPolicyNameAvailable, policyName as QueryKeys],
    () =>
      get<PolicyNameAvailableResponse>({
        path: `policies/available/${policyName}`,
      }),
    {
      ...options,
      enabled: !!policyName && options?.enabled,
      retry: false,
      staleTime: Infinity,
    }
  )
}

export const useRemovePolicy = (
  mutationHooks: ReactQueryMutationHooks<DeletePolicyResponse, string> = {}
) => {
  const queryClient = useQueryClient()
  const mutationOptions = useMutationOptions(mutationHooks, QueryKeys.PoliciesData)
  const { tenant } = useProfile()
  const { del } = useTenantsClient()
  return useMutation((id) => del<DeletePolicyResponse>({ path: `policies/${id}` }), {
    ...mutationOptions,
    onSuccess: (d, id, c) => {
      queryClient.removeQueries([QueryKeys.ListPolicy])
      queryClient.removeQueries([QueryKeys.SystemStatusData, tenant?.id, id])
      queryClient.removeQueries([QueryKeys.PolicyData, id])
      mutationOptions?.onSuccess?.(d, id, c)
    },
  })
}
