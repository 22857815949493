import styled from 'styled-components'
import { theme } from '@aserto/console-common'

export const FullSmallScreenModal = styled.div`
  display: none;
  @media (max-device-width: 1023px) {
    background-color: ${theme.lochivar30};
    display: flex;
    flex-direction: column;
    height: 100%;
    gap: 60px;
    left: 0;
    overflow-x: hidden;
    overflow-y: auto;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 100000;
  }
`
export const Header = styled.div`
  background-color: ${theme.grey10};
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
`
export const LargeText = styled.span`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 120%;
  text-align: center;
  color: ${theme.grey100};
  margin: 20px;
`
export const SmallText = styled(LargeText)`
  font-size: 18px;
`
export const BodyImage = styled.img`
  margin: 20px;
`
