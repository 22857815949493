import React, { useCallback, useMemo, useState } from 'react'
import styled from 'styled-components'
import {
  Input,
  Label,
  SwitchButton,
  theme,
  UseIsNameAvailable,
  ValidatedInput,
} from '@aserto/console-common'

import infoIcon from '../../../assets/info-icon.svg'
import { ConnectionProviderConfig } from '../../../lib/connection'
import { ConfigElement, ConfigElementKind } from '../../../types/local/tenant'
import { LinkRegistry } from '../../../views/RegistryImageDetails/styles'

const InputContainer = styled.div<{ info?: string }>`
  ${({ info }) => (!info ? 'margin-top: 14px' : '')};
`

const InfoIconConnectionsModal = styled.img`
  margin-top: 6px;
  width: 16px;
  height 16px;
`
const Link = styled(LinkRegistry)`
  padding: 0px;
  font-size: 14px;
  font-family: Roboto;
`
const Text = styled.div`
  font-size: 14px;
  font-family: Roboto;
  color: ${theme.grey70};
  padding-top: 4px;
`
const Container = styled.div`
  display: flex;
  gap: 5px;
  margin-top: 14px;
`
const StyledLabel = styled(Label)`
  font-weight: 600;
  font-size: 16px;
  margin-bottom: 8px;
  color: rgb(231, 231, 231);
`
const SwitchContainer = styled.div`
  margin-top: 14px;
  display: flex;
  flex-direction: column;
`
export type DynamicConnectionFormField = {
  name: string
  label: string
  defaultValue?: string
  info?: string
  error?: string
  kind?: ConfigElementKind
  isValid?: boolean
  isUnavailable?: boolean
  useIsAvailable?: UseIsNameAvailable
}

type DynamicConnectionFormProps = {
  configElements: Array<ConfigElement | ConnectionProviderConfig> | null
  onChange: (key: string, value: string | boolean) => void
  defaultFields: Array<DynamicConnectionFormField>
  helpText: string | undefined
  helpUrl: string | undefined
}

export const DynamicConnectionForm: React.FC<DynamicConnectionFormProps> = ({
  configElements,
  onChange,
  defaultFields = [],
  helpText,
  helpUrl,
}) => {
  const formFields = useMemo(
    () =>
      defaultFields.map((d) => {
        return {
          ...d,
          onChange: (value: string | undefined) => {
            onChange(d.name, value || '')
          },
        }
      }),
    [defaultFields, onChange]
  )
  const [switchValues, setSwitchValues] = useState<Record<string, boolean>>({})

  const setSwitch = useCallback(
    (key: string, value: boolean) => {
      const newSwitchValues = { ...switchValues }
      newSwitchValues[key] = value
      setSwitchValues(newSwitchValues)
      onChange(key, value)
      return value
    },
    [onChange, switchValues]
  )

  return (
    <div>
      {formFields.map((field) => {
        return (
          <InputContainer key={field.name}>
            <ValidatedInput
              autoComplete="off"
              className={`selector-${field.name}`}
              data-testid={field.name}
              info={field.info}
              label={field.label}
              type={field.kind === 'CONFIG_ELEMENT_KIND_SECRET' ? 'password' : 'text'}
              useIsAvailable={field.useIsAvailable}
              value={field.defaultValue}
              onChange={field.onChange}
            />
          </InputContainer>
        )
      })}
      <Container>
        {(helpUrl || helpText) && (
          <InfoIconConnectionsModal data-for="registerTip" data-tip src={infoIcon} />
        )}
        {helpUrl ? (
          <Text id="registerTip">
            <Link href={helpUrl} target="_blank">
              Click here
            </Link>
            &nbsp;for docs on where to obtain the values below.
          </Text>
        ) : helpText ? (
          <Text id="registerTip">{helpText}</Text>
        ) : (
          <></>
        )}
      </Container>
      {configElements &&
        configElements
          .filter((key) => !key.generated && !key.read_only)
          .map((configElement) =>
            configElement.mode !== 'DISPLAY_MODE_HIDDEN' &&
            configElement.type === 'CONFIG_ELEMENT_TYPE_BOOLEAN' ? (
              <SwitchContainer key={configElement.id}>
                <StyledLabel>{configElement.description}</StyledLabel>
                <SwitchButton
                  checked={switchValues[configElement.name!] || false}
                  onChange={(boolValue) => setSwitch(configElement.name!, boolValue)}
                />
              </SwitchContainer>
            ) : (
              <InputContainer key={configElement.id}>
                <Input
                  autoComplete="off"
                  className={`selector-${configElement.name}`}
                  data-testid={configElement.name}
                  defaultValue={('value' in configElement && configElement.value) || ''}
                  disabled={configElement.read_only}
                  hidden={configElement.mode === 'DISPLAY_MODE_HIDDEN'}
                  label={
                    configElement.mode === 'DISPLAY_MODE_HIDDEN' ? '' : configElement.description
                  }
                  placeholder={configElement.usage}
                  type={configElement.kind === 'CONFIG_ELEMENT_KIND_SECRET' ? 'password' : 'text'}
                  onChange={(e) => {
                    onChange(configElement.name!, e.target.value)
                  }}
                />
              </InputContainer>
            )
          )}
    </div>
  )
}
